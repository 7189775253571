<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-card class="mx-auto" elevation="5" tile flat width="100%" max-width="500px" style="">
        <v-toolbar color="primary" dark>
          <v-btn icon to="/login" exact>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-container>
            <v-row class="justify-center align-center">
              <img :src="logo" style="max-height: 60px">
            </v-row>
          </v-container>
        </v-toolbar>

        <v-container>
          <v-alert
            text
            align="left"
            border="left"
            type="info"
          >
            <p>Enter your new password. It must contain at least 8 characters.</p>
<!--            <p v-if="user"><strong>Account details:</strong></p>-->
<!--            <small v-if="user">-->
<!--              <pre><strong>UID:</strong> {{ this.user.id }}</pre>-->
<!--              <pre><strong>E-mail:</strong> {{ this.user.email }}</pre>-->
<!--            </small>-->
          </v-alert>
        </v-container>

        <v-card-text align="center">
          <v-form @submit.prevent="onSubmit" v-model="isValid">
            <v-text-field
              v-model="password1"
              :rules="[rules.required, rules.min]"
              label="New password"
              :type="showPassword1 ? 'text' : 'password'"
              :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword1 = !showPassword1"
            >
            </v-text-field>

            <v-text-field
              v-model="password2"
              :rules="[rules.required, rules.min, rules.match]"
              label="Repeat password"
              :type="showPassword2 ? 'text' : 'password'"
              :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword2 = !showPassword2"
            >
            </v-text-field>

            <v-btn class="ma-2" color="primary" type="submit" :disabled="!isValid">Set password</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>

</template>

<script>
import logo from '@/assets/mk-logo-white.svg'

export default {
  data () {
    return {
      logo,
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      isValid: false,
      rules: {
        required: value => !!value || 'Required field',
        min: v => v.length >= 8 || 'Min 8 characters',
        match: v => v === this.password1 || 'Passwords do not match'
      }
    }
  },
  methods: {
    async onSubmit () {
      await this.$store.dispatch('auth/resetPassword', this.password1)
    }
  },
  async mounted () {
    document.title = 'Reset password | Masarka Student Club'
  }
}
</script>
